import React, { Component } from "react";
import styled from 'styled-components';
import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import HomeImageBlock from "../components/home/components/HomeImageBlock";
import HomeNewsBlock from "../components/home/components/HomeNewsBlock";
import HowToOrder from "../components/core/components/HowToOrder";

const WhiteBackground = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: auto;
`;

class ProvisionPage extends Component {
  render() {
    const path = this.props.location.pathname;
    const provisions = get(this, 'props.data.provisions.edges');
    const articles = get(this, 'props.data.articles.edges');
    return (
      <Layout provisions={provisions}>
        <SEO title="Green Chilli"
          fullTitle={true}
          path={path} />
        <WhiteBackground>
          <HowToOrder />
          <HomeImageBlock provisions={provisions} />
          <HomeNewsBlock articles={articles} />
        </WhiteBackground>
      </Layout>
    );
  }
};

export default ProvisionPage;

export const pageQuery = graphql`
  query {
    articles: allContentfulArticle(sort: { fields: createdAt, order: DESC }, limit: 3) {
      edges {
        node {
          id
          slug
          title
          metaDescription
          thumbnail: thumbnail {
            fluid(
              maxWidth: 1920
              quality: 70
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    provisions: allContentfulProvision(sort: {fields: sortOrder, order: ASC})  {
      edges {
        node {
          id
          slug
          title
          metaDescription
          thumbnail: thumbnail {
            fluid(
              maxWidth: 1920
              quality: 70
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
  `;
