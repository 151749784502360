import React from 'react';
import styled from 'styled-components';
import fonts from '../../../styles/fonts'
import colors from '../../../styles/colors';
import Img from 'gatsby-image';
import {Link} from "gatsby";


const HomeNewsItemContainer = styled.div`
    width: 100%;
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 15px;
    padding-top: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media(min-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
    }
`;

const NewsItemTitle = styled.div`
    color: #555555;
    ${fonts.MontserratBold};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
`;

const BorderLineDiv = styled.div`
    height: 4px;
    width: 117px;
    background: #ADE100;
    margin-top: 20px;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        margin-top: 35px;
        margin-bottom: 40px;
    }
`;

const ArticleIntro = styled.div`
    ${fonts.MontserratRegular};
    color: ${colors.grey};
    font-size: 13px;
    line-height: 23px;
`;

const NewsImgLink = styled(Link)`
    height: 175px;
    width: 100%;
    margin-bottom: 20px;
    
    @media(min-width: 992px) {
      height: 300px;
    }
`;

const NewsImg = styled(Img)`
  width: 100%;
  height: 100%;
`;

const NewsTextContainer = styled.div`
    max-width: 330px;
    
    a {
      text-decoration: none;
    }
    
    @media(min-width: 768px) {
        margin: auto;
    }
`;


const HomeNewsItem = (props) => {
  const {article} = props;

  const link = `/article/${article.slug}`;

  return (
    <HomeNewsItemContainer>
      <NewsImgLink to={link}>
        <NewsImg fluid={article.thumbnail.fluid} alt={article.title}/>
      </NewsImgLink>
      <NewsTextContainer>
        <Link to={link}>
          <NewsItemTitle>{article.title}</NewsItemTitle>
        </Link>
        <BorderLineDiv/>
        <ArticleIntro>{article.metaDescription}</ArticleIntro>
      </NewsTextContainer>
    </HomeNewsItemContainer>
  )
}

export default HomeNewsItem;
