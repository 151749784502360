import React, { Component } from "react";
import styled from 'styled-components';
import fonts from "../../../styles/fonts";
import colors from "../../../styles/colors";
import TermsAndPrivacyAgreement from "./TermsAndPrivacyAgreement";


const SignUp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    bix-sizing: border-box;
    width: 100%;
    @media(min-width: 992px) {
        margin-top: -20px;
    }
`;

const SignUpEmail = styled.div`
    width: 100%;
    max-width: 355px;
    input {
        background: ${colors.white};
        height: 44px;
        margin-bottom: 5px;
        box-sizing: border-box;
        width: 100%;
        padding: 0;
        box-sizing: border-box;
        border: 1px solid ${colors.borderGrey};
        border-radius: 0;
        color: ${colors.grey};
        ${fonts.MontserratMedium};
        font-size: 12px;
        line-height: 15px;
        padding-left: 15px;
        padding-right: 15px;
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: ${colors.grey};
            ${fonts.MontserratMedium};
            font-size: 12px;
            line-height: 15px;
            text-transform: none;
        }
        ::-moz-placeholder { /* Firefox 19+ */
            color: ${colors.grey};
            ${fonts.MontserratMedium};
            font-size: 12px;
            line-height: 44px;
            text-transform: none;
            //padding-left: 15px;
        }
        :-ms-input-placeholder { /* IE 10+ */
            color: ${colors.grey};
            ${fonts.MontserratMedium};
            font-size: 12px;
            line-height: 15px;
            text-transform: none;
            //padding-left: 15px;
        }
        :-moz-placeholder { /* Firefox 18- */
            color: ${colors.grey};
            ${fonts.MontserratMedium};
            font-size: 12px;
            line-height: 44px;
            text-transform: none;
            //padding-left: 15px;
        }
        :-webkit-autofill,
        :-webkit-autofill:hover, 
        :-webkit-autofill:focus, 
        :-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 27.5px ${colors.white} inset !important;
        }
        :-webkit-autofill {
            -webkit-text-fill-color:  ${colors.grey} !important;
            ${fonts.MontserratMedium};
            color: ${colors.grey};
            font-size: 14px;
            line-height: 22px;
        }
        }
        }
    `;

const SignUpButton = styled.button`
    -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        background: ${colors.white};
        ${fonts.MontserratMedium};
        height: 44px;
        width: 117px;
        text-decoration: none;
        color: ${colors.grey};
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 1.8px;
        border-radius: 0;
        border: 1px solid ${colors.green};
        text-transform: uppercase;
        transition: all 0.5s;
        margin: 0px;
        outline: none;
        &:hover,
        &:active,
        &:focus
        &:hover {
            text-decoration: none;
            outline: none;
            box-shadow: none;
            color: ${colors.white};
            background: ${colors.green};
        }
`;

const ErrorMessage = styled.div`
    ${fonts.MontserratRegular};
    font-size: 10px;
    color: ${colors.grey};
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
    width: 100%;
`;

const NewsletterSignUpTitle = styled.div`
   ${fonts.CormorantGaramondItalic};
    font-size: 40px;
    color: ${colors.grey};
    text-align: left;
    line-height: 48px;
    margin-bottom: 10px;
`;

const NewsletterSignUpSubtitle = styled.div`
    ${fonts.MontserratRegular};
    font-size: 13px;
    color: ${colors.grey};
    text-align: left;
    line-height: 23px;
    margin-bottom: 25px;
`;


class SignUpForm extends Component {
  state = {
    email: '',
    name: ''
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.submit();
    }
  };


  submit = () =>
    this.state.email &&
    this.state.email.indexOf("@") > -1 &&
    this.props.onSubmitted({
      EMAIL: this.state.email,
      FNAME: this.state.name
    });

  render() {

    const { status, message } = this.props;
    const { handleKeyPress, submit } = this;

    return (<>{status === 'success' ? 
            <>
                <NewsletterSignUpTitle>Success!</NewsletterSignUpTitle>
                <NewsletterSignUpSubtitle>Thank you for subscribing.</NewsletterSignUpSubtitle>
            </> 
            : 
            <>
                <SignUp>
                    <SignUpEmail>
                        <input 
                                required
                                placeholder='Name' 
                                onKeyPress={handleKeyPress}
                                onChange={(event) => this.setState({ name: event.target.value })} 
                                type="text"
                                aria-label="Your name" />
                        <input 
                            required
                            placeholder='Email Address' 
                            onKeyPress={handleKeyPress}
                            onChange={(event) => this.setState({ email: event.target.value })} 
                            type="email"
                            aria-label="Your email address" />
                    </SignUpEmail>
                <SignUpButton aria-label="Send" onClick={submit}>Send</SignUpButton> 
                {status === "error" ? 
                    <ErrorMessage
                    dangerouslySetInnerHTML={{ __html: message }}
                    />
                    : 
                    <div style={{ height: '23px' }} />}
                    <TermsAndPrivacyAgreement type="news"/>
                </SignUp>
                </>}
            </>
    );
  }
};

export default SignUpForm;
