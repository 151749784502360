import React, { Component } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import fonts from '../../../styles/fonts'
import HomeNewsItem from './HomeNewsItem';
import colors from '../../../styles/colors';
import MailchimpSignUpNews from '../../core/components/MailchimpSignUpNews';

const HomeIntroductionBlockContainer = styled.div`
    width: 100%;
`;

const BeigeContainer = styled(Container)`
    background: ${colors.beige};
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 45px;
    padding-bottom: 30px;
    box-sizing: border-box;
    @media(min-width: 992px) {
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 55px;
        padding-bottom: 55px;
    }
`;

const RowStyled = styled(Row)`
    height: 100%;
    max-width: 1400px;
    margin: auto;
    @media(min-width: 768px) {
        margin-left: ${props => props.articles && '-15px'};
        width: ${props => props.articles && 'calc(100% + 30px)'};
    }
}
`;

const ColStyled = styled(Col)`
    height: 100%;
`;

const AboutUsTextContainer = styled.div`
    height: 100%;
    box-sizing: border-box;
    // padding-left: 90px;
    // padding-right: 90px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const HomeNewsBlockTitle = styled.div`
    color: ${colors.grey};
    ${fonts.MontserratBold};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
`;

const BorderLineDiv = styled.div`
    height: 4px;
    width: 117px;
    background: ${colors.green};
    margin-top: 20px;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        margin-top: 35px;
        margin-bottom: 40px;
    }
`;

const AboutUsDescription = styled.div`
    color: ${colors.grey};
   ${fonts.CormorantGaramondLight};
   font-size: 40px;
   line-height: 48px;
   max-width: 625px;
   padding-bottom: 40px;
   @media(min-width: 992px) {
        font-size: 50px;
        line-height: 58px;
        padding-right: 40px;
    }
`;

const LoadMoreButton = styled.button`
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    background: ${colors.white};
    ${fonts.MontserratMedium};
    height: 44px;
    width: 230px;
    margin: auto;
    text-decoration: none;
    color: ${colors.grey};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.8px;
    border-radius: 0;
    border: 1px solid ${colors.green};
    text-transform: uppercase;
    transition: all 0.5s;
    margin: 0px;
    margin-top: 15px;
    outline: none;
    &:hover,
    &:active,
    &:focus
    &:hover {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        color: ${colors.white};
        background: ${colors.green};
    }
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: ${props => props.visible === true && 'none'};
`;

const url = "https://greenchilli.us4.list-manage.com/subscribe/post?u=6889ac4c1d10d2c8742b76703&amp;id=9597dc0a75";

class HomeNewsBlock extends Component {
    state = {
        loadedArticles: 2
    };

    render () {
    const { articles } = this.props;
    const displayedArticles = articles.filter((article, index) => index <= this.state.loadedArticles);
    return (
        <HomeIntroductionBlockContainer>
            <BeigeContainer>
                <RowStyled>
                    <ColStyled md={8}>
                        <AboutUsTextContainer>
                            <HomeNewsBlockTitle>News</HomeNewsBlockTitle>
                            <BorderLineDiv />
                            <AboutUsDescription>Share our journey with tales from the farm to the galley.</AboutUsDescription>
                        </AboutUsTextContainer>
                    </ColStyled>
                    <ColStyled md={4}>
                        <HomeNewsBlockTitle>Sign up to our news</HomeNewsBlockTitle>
                        <BorderLineDiv />
                       <MailchimpSignUpNews url={url} />
                    </ColStyled>
                </RowStyled>
                <RowStyled articles>
                    {displayedArticles.map((article, index) =>
                        <ColStyled md={4} key={index}>
                            <HomeNewsItem article={article.node} />
                        </ColStyled>
                    )}
                </RowStyled>
                <ButtonContainer visible={articles.length <= displayedArticles.length}>
                    <LoadMoreButton onClick={() => this.setState({loadedArticles: this.state.loadedArticles + 3})}>Load More</LoadMoreButton>
                </ButtonContainer>
            </BeigeContainer>
        </HomeIntroductionBlockContainer>
        )
    }
}

export default HomeNewsBlock;
