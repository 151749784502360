import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import fonts from '../../../styles/fonts';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import {Link} from 'gatsby';


const HomeImageBlockContainer = styled(Container)`
    width: 100%;
    box-sizing: border-box;
    @media(min-width: 768px) {
        padding: 50px;
        padding-bottom: 22.5px;
        padding-top: 22.5px;
        margin: auto;
    }
`;

const MainImageContainer = styled.div`
    width: 100%;
    height: 375px;
    position: relative;
    margin-bottom: 15px;
    margin-top: 15px;
    @media(min-width: 768px) {
        margin-bottom: 22.5px;
        margin-top: 22.5px;
        height: 350px;
    }
`;

const MainImg = styled(Img)`
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
`;

const ImgOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: black;
    opacity: 0.2;
`;

const MainImageTitle = styled.div`
    color: white;
    ${fonts.CormorantGaramondBold};
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 6px;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const BlockImageContainer = styled.div`
  width: 100%;
    position: relative;
    box-sizing: border-box;
    // padding: 15px;
    display: block;
    margin-bottom: 15px;
    margin-left: 0px;
    margin-right: 0px;
    @media(min-width: 576px) {
      margin-left: 7.5px;
      margin-right: 7.5px;
      width: calc(100% - 15px);
    }
    @media(min-width: 768px) {
        max-height: 275px;
        width: calc(100% - 45px);
        margin-left: 22.5px;
        margin-right: 22.5px;
        margin-top: 22.5px;
        margin-bottom: 22.5px;
    }
`;

const BlockContainer = styled.div`
    display: block;
    width: 100%;
    position: relative;
`;

const RowStyled = styled(Row)`
    @media(min-width: 576px) {
      margin-left: -7.5px;
      margin-right: -7.5px;
    }
    @media(min-width: 768px) {
        margin-left: -22.5px;
        margin-right: -22.5px;
    }
`;

const HomeImageBlock = (props) => {
  const {provisions} = props;
  
  let organic;
  let provisionsData = [];
  provisions.forEach((provision) => {
    let node = provision.node;
    
    if (node.slug !== 'organic-and-sustainable') {
      provisionsData.push(node);
    } else {
      organic = node;
    }
  });
  
  return (
    <HomeImageBlockContainer>
      {organic && 
      <MainImageContainer>
        <Link to={`/provision/${organic.slug}`}>
          <MainImageTitle>{organic.title}</MainImageTitle>
          <MainImg fluid={organic.thumbnail.fluid} alt={organic.title} />
          <ImgOverlay />
        </Link>
      </MainImageContainer>
      }
      <RowStyled>
        {provisionsData.map((provision, index) =>
          <Col sm={6} md={6} lg={4} key={index}>
            <BlockImageContainer>
              <BlockContainer>
                <Link to={`/provision/${provision.slug}`}>
                  <MainImageTitle>{provision.title}</MainImageTitle>
                  <Img fluid={provision.thumbnail.fluid} alt={provision.title}/>
                  <ImgOverlay />
                </Link>
              </BlockContainer>
            </BlockImageContainer>
          </Col>
        )}
      </RowStyled>
    </HomeImageBlockContainer>
  )
  }

export default HomeImageBlock;
